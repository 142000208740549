export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [2,3,7];

export const dictionary = {
		"/": [9],
		"/(app)/client": [10,[2]],
		"/(app)/do-test": [11,[2]],
		"/(app)/do-test/apac": [12,[2]],
		"/(app)/do-test/apac/[id]/confirmed_sentence": [13,[2,3]],
		"/(app)/do-test/apac/[id]/confirmed_word": [14,[2,3]],
		"/(app)/do-test/apac/[id]/information": [15,[2,3]],
		"/(app)/do-test/apac/[id]/non_meaning": [16,[2,3]],
		"/(app)/do-test/apac/[id]/result": [17,[2,3]],
		"/(app)/do-test/apac/[id]/sentence": [18,[2,3]],
		"/(app)/do-test/apac/[id]/submit": [19,[2,3]],
		"/(app)/do-test/apac/[id]/word": [20,[2,3]],
		"/(app)/do-test/choose": [21,[2]],
		"/(app)/do-test/snsb-y": [22,[2]],
		"/(app)/do-test/snsb-y/[id]/information": [23,[2,4]],
		"/(app)/do-test/snsb-y/[id]/record-paper": [24,[2,4]],
		"/(app)/do-test/snsb-y/[id]/result": [25,[2,4]],
		"/(app)/do-test/snsb-y/[id]/retrieve-old-data": [26,[2,4]],
		"/(app)/do-test/snsb-y/[id]/submit": [27,[2,4]],
		"/(app)/do-test/snsb-y/[id]/summary-paper/left": [28,[2,4,5]],
		"/(app)/do-test/snsb-y/[id]/summary-paper/right": [29,[2,4,5]],
		"/(app)/do-test/snsb2": [30,[2]],
		"/(app)/do-test/snsb2/[id]/attention": [31,[2,6]],
		"/(app)/do-test/snsb2/[id]/frontal_executive_functions": [32,[2,6]],
		"/(app)/do-test/snsb2/[id]/information": [33,[2,6]],
		"/(app)/do-test/snsb2/[id]/language_related_functions": [34,[2,6]],
		"/(app)/do-test/snsb2/[id]/memory": [35,[2,6]],
		"/(app)/do-test/snsb2/[id]/other_indexes": [36,[2,6]],
		"/(app)/do-test/snsb2/[id]/svlt_rcft": [37,[2,6]],
		"/(app)/do-test/snsb2/[id]/visuospatial_functions": [38,[2,6]],
		"/login": [46,[7]],
		"/report/apac/resultprint": [48,[8]],
		"/report/apac/result": [47,[8]],
		"/report/apac/soundTable": [49,[8]],
		"/report/apac/summaryconfirm": [51,[8]],
		"/report/apac/summaryprint": [52,[8]],
		"/report/apac/summary": [50,[8]],
		"/(app)/result/apac/reportprint": [40,[2]],
		"/(app)/result/apac/report": [39,[2]],
		"/(app)/result/apac/summaryprint": [42,[2]],
		"/(app)/result/apac/summary": [41,[2]],
		"/(app)/result/chart": [43,[2]],
		"/(app)/result/text": [44,[2]],
		"/(app)/test": [45,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';